<template>
  <div class="pagenotfoiund">
    <img src="../assets/images/404.png" alt="">
    <p>Sorry!!! Currently the page you are looking for is not exist in our system.</p>
    <p class="oops">OOPS!! Page Not Found</p>
    <a-button type="primary">
      <router-link to="/dashboard"> <HomeOutlined /> Back To Home</router-link>
    </a-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    HomeOutlined
  },
  setup () {
    const loading = ref<boolean>(false)
    return {
      loading
    }
  }
})
</script>

<style lang="scss">
.pagenotfoiund {
  background-color: #e9fdff;
  height: 100vh;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .oops {
    font-size: 25px;
  }
  button {
    border-color: #7EAF1A;
    background: #7EAF1A;
    color: #ffffff;
    border-radius: 15px;
  }
  img {
    width: 300px;
  }
}
</style>
